import { Box, Container, Typography } from '@mui/material'
import type { SingleImageFragment } from './SingleImage.gql'
import { CraftImage } from '../../CraftImage'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'

const name = 'CraftSingleImage' as const
const parts = ['root', 'image', 'caption'] as const
const { classes } = extendableComponent(name, parts)

export type SingleImageProps = SingleImageFragment

export function SingleImage(props: SingleImageProps) {
  const { caption, flow, size, image } = props

  if (!image || image.length === 0) {
    return null
  }

  const [singleImage] = image

  if (!singleImage) {
    return null
  }

  let float = 'initial'
  if (flow === 'floatLeft' || flow === 'floatRight') {
    float = flow.replace('float', '').toLowerCase()
  }

  return (
    <Container className={classes.root}>
      <CraftImage
        sx={(theme) => ({
          margin: 0,
          width: size ? `${size}%` : null,
          float,

          '& img': {
            display: 'block',
            width: '100%',
            height: 'auto',
          },

          [theme.breakpoints.up('md')]: {
            marginLeft: float === 'right' ? theme.spacings.md : null,
            marginRight: float === 'left' ? theme.spacings.md : null,
          },

          [theme.breakpoints.down('md')]: {
            marginLeft: float === 'right' ? theme.spacings.sm : null,
            marginRight: float === 'left' ? theme.spacings.sm : null,
          },
        })}
        image={singleImage}
        imageClassName={classes.image}
        captionClassName={classes.caption}
        caption={caption}
      />
    </Container>
  )
}
