import { PageContentComponentProps } from '../../../../types'
import type { HeadingFragment } from './Heading.gql'
import { Container, Typography } from '@mui/material'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'

const name = 'CraftHeading' as const
const parts = ['root', 'content'] as const
const { classes } = extendableComponent(name, parts)

export type HeadingProps = PageContentComponentProps & HeadingFragment

export function Heading(props: HeadingProps) {
  const { text, hierarchyLevel } = props

  let heading

  switch (hierarchyLevel) {
    case 'H1':
      heading = (
        <Typography variant='h1' component='h1' className={classes.content}>
          {text}
        </Typography>
      )
      break
    case 'H2':
      heading = (
        <Typography variant='h2' component='h2' className={classes.content}>
          {text}
        </Typography>
      )
      break
    case 'H3':
      heading = (
        <Typography variant='h3' component='h3' className={classes.content}>
          {text}
        </Typography>
      )
      break
  }

  if (heading) {
    return <Container className={classes.root}>{heading}</Container>
  }

  return null
}
