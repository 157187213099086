import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { Box } from '@mui/material'

const name = 'FeefoWidget' as const
const parts = ['root'] as const
const { classes } = extendableComponent(name, parts)

type WidgetProps = {
  // widgetType is currently unused, but it's here in case we need it in the future
  // eslint-disable-next-line react/no-unused-prop-types
  widgetType?: string | null
}

export function Widget(props: WidgetProps) {
  return (
    <Box
      className={`${classes.root} feefo-review-carousel-widget-service`}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',

        '& > iframe': {
          height: '362px',
          width: '100%',
          overflow: 'hidden',
          border: 'none',
        },
      }}
    >
      <iframe src='/feefo.html' title='Feefo' />
    </Box>
  )
}
