import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { Image } from '@graphcommerce/image'
import { Container, Box, Link, LinkProps, BoxProps } from '@mui/material'
import { BrandHighlight } from '../../../BrandHighlight'
import { Icon } from '../../../Usps/Icon'
import { UspFragment } from './Usp.gql'
import BorderDefault from './Border/default.svg'
import BorderArrow from './Border/arrow.svg'

type UspContainerProps = React.PropsWithChildren<{ url?: string | null } & LinkProps & BoxProps>

const name = 'CraftUsp' as const
const parts = ['root', 'item', 'icon', 'title'] as const
const { classes } = extendableComponent(name, parts)

const UspContainer = ({ children, url, sx, ...props }: UspContainerProps) => {
  if (typeof url === 'string') {
    return (
      <Link
        href={url}
        sx={[{ color: 'inherit', textDecoration: 'none' }, ...(Array.isArray(sx) ? sx : [sx])]}
        {...props}
      >
        {children}
      </Link>
    )
  }

  return <Box {...props}>{children}</Box>
}

const uspBorders = {
  default: BorderDefault,
  arrow: BorderArrow,
}

type UspBorderProps = {
  type?: keyof typeof uspBorders | string | null
}

const UspBorder = ({ type }: UspBorderProps) => {
  const borderType = type && type in uspBorders ? type : ('default' as keyof typeof uspBorders)
  return <Image src={uspBorders[borderType].src} layout='fill' alt='' unoptimized />
}

export type UspProps = UspFragment

export const Usp = (props: UspProps) => {
  const {
    uspTitle1,
    uspIcon1,
    uspBorder1,
    uspUrl1,
    uspTitle2,
    uspIcon2,
    uspBorder2,
    uspUrl2,
    uspTitle3,
    uspIcon3,
    uspBorder3,
    uspUrl3,
    uspTitle4,
    uspIcon4,
    uspBorder4,
    uspUrl4,
  } = props

  const uspItems = [
    {
      title: uspTitle1,
      icon: uspIcon1,
      border: uspBorder1,
      url: uspUrl1,
    },
    {
      title: uspTitle2,
      icon: uspIcon2,
      border: uspBorder2,
      url: uspUrl2,
    },
    {
      title: uspTitle3,
      icon: uspIcon3,
      border: uspBorder3,
      url: uspUrl3,
    },
    {
      title: uspTitle4,
      icon: uspIcon4,
      border: uspBorder4,
      url: uspUrl4,
    },
  ].filter((item) => !!item.title && !!item.icon)

  return (
    <Container
      className={classes.root}
      sx={(theme) => ({
        display: 'grid',
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: `${theme.spacings.xs} 0`,
        gridTemplateColumns: 'repeat(2, 1fr)',
        rowGap: theme.spacings.xxs,

        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: `repeat(${uspItems.length}, 1fr)`,
        },
      })}
    >
      {!!uspItems.length &&
        uspItems.map(({ title, icon, border, url }) => {
          if (title) {
            return (
              <UspContainer
                className={classes.item}
                key={`${title}${icon}`}
                url={url}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                {!!icon && (
                  <Box
                    className={classes.icon}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr',
                      gridAutoColumns: '1fr',
                      width: 82,
                      height: 82,
                      justifyItems: 'center',
                      alignItems: 'center',
                      '& > *': { gridRow: '1 / -1', gridColumn: '1 / -1' },
                    }}
                  >
                    <UspBorder type={border} />
                    <Icon icon={icon} alt={title} />
                  </Box>
                )}
                <Box
                  className={classes.title}
                  sx={(theme) => ({ marginTop: theme.spacings.xxxs, textTransform: 'uppercase' })}
                >
                  <BrandHighlight>{title}</BrandHighlight>
                </Box>
              </UspContainer>
            )
          }

          return null
        })}
    </Container>
  )
}
