import { TextFieldElement } from '@graphcommerce/ecommerce-ui'
import { ApolloCustomerErrorAlert } from '@graphcommerce/magento-customer/components/ApolloCustomerError/ApolloCustomerErrorAlert'
import { Button, Form, FormActions, FormRow, extendableComponent } from '@graphcommerce/next-ui'
import { phonePattern, useFormGqlMutation } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Alert } from '@mui/material'
import { ContactDocument } from './Contact.gql'
import { ProductInterface } from '../../.mesh'

const componentName = 'ContactForm' as const

const parts = ['contactForm'] as const
const { classes } = extendableComponent(componentName, parts)

const subjectList = {
  general: '',
  artwork: 'Question about artwork: $ARTWORK',
  viewing: 'Request a viewing of: $ARTWORK',
  commission: 'Commision piece by: $ARTIST',
  ownart: 'Own Art Enquiry Regarding: $ARTWORK',
} as const

export type ContactFormType = keyof typeof subjectList

export const contactFormTypes = Object.keys(subjectList) as ContactFormType[]

export function isContactFormType(type: string): type is ContactFormType {
  return !!contactFormTypes.find((contactFormType) => contactFormType === type)
}

export type ContactFormQueryParams =
  | {
      type: Extract<ContactFormType, 'general'>
    }
  | {
      type: Exclude<ContactFormType, 'general'>
      product: Pick<ProductInterface, 'sku' | 'name' | 'artist_option_label'>
    }

export type ContactFormProps = {
  customSuccessMessage?: string
  customErrorMessage?: string
  customSubmitButtonText?: string
} & ContactFormQueryParams

export function ContactForm(props: ContactFormProps) {
  const { type, customSuccessMessage, customErrorMessage, customSubmitButtonText } = props

  let subjectDefaultValue: string | null = null
  let skuValue: string | null = null

  console.log(props)

  if (type !== 'general') {
    subjectDefaultValue = subjectList[type]

    const {
      product: { sku, name, artist_option_label },
    } = props

    if (sku && name && artist_option_label) {
      subjectDefaultValue = subjectDefaultValue
        .replace('$ARTWORK', name || '')
        .replace('$ARTIST', artist_option_label || '')
      skuValue = sku
    }
  } else {
    subjectDefaultValue = subjectList[type]
  }

  const form = useFormGqlMutation(ContactDocument, {
    mode: 'onSubmit',

    defaultValues: {
      type,
      subject: subjectDefaultValue,
      productSku: skuValue,
    },
  })

  const { handleSubmit, formState, error, data, control } = form
  const submitHandler = handleSubmit(() => {})

  // Handle success message
  if (formState.isSubmitSuccessful && data && data.contactSubmit?.success) {
    return (
      <Alert severity='success' variant='standard'>
        {customSuccessMessage && <Trans id={customSuccessMessage} />}

        {!customSuccessMessage && data.contactSubmit?.response && (
          <Trans id={data.contactSubmit?.response} />
        )}

        {!customSuccessMessage && !data.contactSubmit?.response && (
          <Trans id='Contact form successfully submitted' />
        )}
      </Alert>
    )
  }

  // Handle error message
  if (formState.isSubmitSuccessful && data && !data.contactSubmit?.success) {
    return (
      <Alert severity='error' variant='standard'>
        {customErrorMessage && <Trans id={customErrorMessage} />}

        {!customErrorMessage && data.contactSubmit?.response && (
          <Trans id={data.contactSubmit?.response} />
        )}

        {!customErrorMessage && !data.contactSubmit?.response && (
          <Trans id='An error occurred while submitting contact form' />
        )}
      </Alert>
    )
  }

  return (
    <Form onSubmit={submitHandler} className={classes.contactForm} noValidate>
      <FormRow>
        <TextFieldElement
          name='fullName'
          type='text'
          control={control}
          variant='outlined'
          label={<Trans id='Name' />}
          required
          disabled={formState.isSubmitting}
        />
      </FormRow>
      <FormRow>
        <TextFieldElement
          name='email'
          type='email'
          control={control}
          variant='outlined'
          autoComplete='email'
          label={<Trans id='Email' />}
          required
          disabled={formState.isSubmitting}
        />
      </FormRow>

      <FormRow>
        <TextFieldElement
          name='telephone'
          type='text'
          control={control}
          variant='outlined'
          label={<Trans id='Telephone' />}
          required
          validation={{
            pattern: { value: phonePattern, message: i18n._(/* i18n */ 'Invalid phone number') },
          }}
          helperText={<Trans id='Please include country code, eg +441239990000' />}
          disabled={formState.isSubmitting}
        />
      </FormRow>

      <FormRow>
        <TextFieldElement
          name='subject'
          control={control}
          variant='outlined'
          label={<Trans id='Subject' />}
          required
          InputProps={{ readOnly: type !== 'general' }}
          disabled={!!subjectDefaultValue || formState.isSubmitting}
          sx={{
            '& .Mui-disabled input': {
              color: 'inherit',
              '-webkit-text-fill-color': 'inherit',
            },
            '& .Mui-disabled': {
              color: 'inherit',
            },
          }}
        />
      </FormRow>

      {!!skuValue && (
        <FormRow>
          <TextFieldElement
            name='productSku'
            control={control}
            variant='outlined'
            label={<Trans id='Product SKU' />}
            required
            disabled
            sx={{
              '& .Mui-disabled input': {
                color: 'inherit',
                '-webkit-text-fill-color': 'inherit',
              },
              '& .Mui-disabled': {
                color: 'inherit',
              },
            }}
          />
        </FormRow>
      )}

      <FormRow>
        <TextFieldElement
          name='message'
          type='text'
          control={control}
          variant='outlined'
          label={<Trans id='Message' />}
          required
          multiline
          minRows={3}
          disabled={formState.isSubmitting}
        />
      </FormRow>

      <FormActions
        sx={(theme) => ({
          padding: `${theme.spacings.xxs} ${theme.spacings.xs} 0 ${theme.spacings.xs}`,
        })}
      >
        <Button
          type='submit'
          color='primary'
          variant='pill'
          size='large'
          loading={formState.isSubmitting}
        >
          {customSubmitButtonText ? <Trans id={customSubmitButtonText} /> : <Trans id='Submit' />}
        </Button>
      </FormActions>

      <ApolloCustomerErrorAlert error={error} />
    </Form>
  )
}
