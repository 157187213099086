import { Theme } from '@mui/material'

export const sxLargeItem = (theme: Theme) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacings.xs,

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  '& .ProductListItem-root': {
    '& a': {
      textDecoration: 'none',
    },
  },

  '& .ProductListItem-imageContainer': {
    padding: '100% 0 0 0',
    marginBottom: theme.spacings.xxxs,

    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },

  '& .ProductListItem-title': {
    ...theme.typography.body2,
    lineHeight: 1.6,
  },
})
