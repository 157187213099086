import { Image } from '@graphcommerce/image'
import { CSSProperties, useEffect, useState } from 'react'
import { Container, Box, Link, BoxProps, LinkProps, Typography } from '@mui/material'
import { AnimatePresence, m } from 'framer-motion'
import type { GalleryHeroFragment } from './HeroGallery.gql'
import { CraftImageFragment } from '../../../../graphql/CraftImageFragment.gql'
import { CraftImage } from '../../CraftImage'
import { PageContentComponentProps } from '../../../../types'
import IconLinkCaretRight from './link-caret-right.svg'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'

const name = 'CraftHeroGallery' as const
const parts = [
  'root',
  'slides',
  'slide',
  'slideLayout',
  'slideLinkOverlay',
  'slideImage',
  'slideContent',
  'slideContentOverlay',
  'slideTitle',
  'slideText',
  'switcher',
  'switcherButton',
] as const
const { classes, selectors } = extendableComponent(name, parts)

export type HeroGalleryProps = GalleryHeroFragment &
  PageContentComponentProps & {
    slideDuration?: number
  }

type HeroGallerySlide = {
  title?: string | null
  url?: string | null
  text?: string | null
  image: Array<CraftImageFragment>
}

export const HeroGallery = (props: HeroGalleryProps) => {
  const {
    index,
    slideDuration = 8000,
    heroTitle1,
    heroButtonUrl1,
    heroButtonText1,
    heroImage1,
    heroTitle2,
    heroButtonUrl2,
    heroButtonText2,
    heroImage2,
    heroTitle3,
    heroButtonUrl3,
    heroButtonText3,
    heroImage3,
  } = props

  const slides: Array<HeroGallerySlide> = []

  const slidesData = [
    { title: heroTitle1, url: heroButtonUrl1, text: heroButtonText1, image: heroImage1 },
    { title: heroTitle2, url: heroButtonUrl2, text: heroButtonText2, image: heroImage2 },
    { title: heroTitle3, url: heroButtonUrl3, text: heroButtonText3, image: heroImage3 },
  ]

  slidesData.forEach((slideData) => {
    if (Array.isArray(slideData.image) && slideData.image.length) {
      slides.push({
        title: slideData.title,
        url: slideData.url,
        text: slideData.text,
        image: slideData.image.filter(Boolean) as Array<CraftImageFragment>,
      })
    }
  })

  const [activeSlide, setActiveSlide] = useState(0)
  const { image, title, text, url } = slides[activeSlide]

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      if (activeSlide === slides.length - 1) {
        setActiveSlide(0)
      } else {
        setActiveSlide((prevActiveSlide) => prevActiveSlide + 1)
      }
    }, slideDuration)

    return () => clearInterval(sliderInterval)
  })

  return (
    <Container
      className={classes.root}
      sx={(theme) => ({
        marginTop: index === 0 ? `calc(${theme.spacings.sm} * -1 - 1px)` : '',
      })}
    >
      <Box className={classes.slides}>
        <AnimatePresence initial={false} mode='popLayout'>
          <m.div
            className={classes.slide}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
            key={`${title}${image.map((i) => i.id).join()}`}
          >
            <Box
              className={classes.slideLayout}
              sx={(theme) => ({
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridTemplateRows: '1fr',
                position: 'relative',

                [theme.breakpoints.down('sm')]: {
                  minHeight: '220px',
                },
              })}
            >
              {url && (
                <Link
                  className={classes.slideLinkOverlay}
                  href={url}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 2,
                  }}
                />
              )}
              <CraftImage
                imageClassName={classes.slideImage}
                image={image[0]}
                ratio={{ x: 28, y: 9 }}
                sx={(theme) => ({
                  gridColumn: '1 / -1',
                  gridRow: '1 / -1',

                  [theme.breakpoints.down('sm')]: {
                    minHeight: '100%',

                    [selectors.slideImage]: {
                      minHeight: '100%',

                      '& img': {
                        objectFit: 'cover',
                        minHeight: '100%',
                      },
                    },
                  },
                })}
              />
              {title && (
                <Container
                  className={classes.slideContent}
                  sx={(theme) => ({
                    display: 'flex',
                    gridColumn: '1 / -1',
                    gridRow: '1 / -1',
                    flexDirection: 'column',
                    zIndex: 1,

                    [theme.breakpoints.up('sm')]: {
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                    },

                    [theme.breakpoints.down('sm')]: {
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: theme.page.horizontal,
                      paddingBottom: theme.page.horizontal,
                    },
                  })}
                >
                  <Box
                    className={classes.slideContentOverlay}
                    sx={(theme) => ({
                      textDecoration: 'none',
                      background: 'rgba(255, 255, 255, 0.9)',
                      color: 'inherit',
                      padding: `${theme.spacings.xs} ${theme.spacings.sm}`,

                      [theme.breakpoints.up('sm')]: {
                        margin: theme.spacings.sm,
                        maxWidth: '500px',
                      },
                    })}
                  >
                    <Box className={classes.slideTitle} sx={{ gridArea: 'title' }}>
                      <Typography variant='h3'>{title}</Typography>
                    </Box>
                    {text && (
                      <Box
                        className={classes.slideText}
                        sx={{
                          gridArea: 'text',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Typography variant='body2'>{text}</Typography>
                        <Image
                          src={IconLinkCaretRight}
                          unoptimized
                          sx={{
                            height: '0.6em',
                            width: 'auto',
                            filter: 'invert(1)',
                            marginLeft: '0.4em',
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Container>
              )}
            </Box>
          </m.div>
        </AnimatePresence>
      </Box>
      <Box
        className={classes.switcher}
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          gap: theme.spacings.xxxs,
          marginTop: theme.spacings.xs,
        })}
      >
        {slides.map((slide, i) => (
          <m.div
            key={i} // eslint-disable-line react/no-array-index-key
            initial={activeSlide === i ? 'active' : 'inactive'}
            animate={activeSlide === i ? 'active' : 'inactive'}
            variants={{
              active: { opacity: 'var(--opacity-active)' },
              inactive: { opacity: 'var(--opacity-inactive)' },
            }}
            style={
              {
                '--opacity-active': 1,
                '--opacity-inactive': 0.2,
              } as CSSProperties
            }
          >
            <Box
              className={classes.switcherButton}
              sx={(theme) => ({
                cursor: 'pointer',
                width: '12px',
                height: '12px',
                background: theme.palette.background.dark,
                borderRadius: '100%',
              })}
              onClick={() => setActiveSlide(i)}
            />
          </m.div>
        ))}
      </Box>
    </Container>
  )
}
