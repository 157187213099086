// type CraftImageProps = CraftImageFr
import { Box, SxProps, Theme, Typography } from '@mui/material'
import { CraftImageFragment } from '../../graphql/CraftImageFragment.gql'

export type CraftImageProps = {
  image: CraftImageFragment
  caption?: React.ReactNode
  alt?: string
  ratio?: { x: number; y: number }
  sx?: SxProps<Theme>
  imageClassName?: string
  captionClassName?: string
}

export const CraftImage = ({
  image,
  caption,
  ratio,
  sx,
  alt,
  imageClassName,
  captionClassName,
}: CraftImageProps) => {
  let ratioPadding
  if (typeof ratio !== 'undefined') {
    ratioPadding = `${ratio.y / (ratio.x / 100)}%`
  }

  if (!image.url) {
    return null
  }

  return (
    <Box component='figure' sx={[(theme) => ({ margin: 0 }), ...(Array.isArray(sx) ? sx : [sx])]}>
      <Box
        className={imageClassName}
        sx={{
          paddingTop: ratioPadding,
          position: typeof ratioPadding === 'string' ? 'relative' : '',
          '& img':
            typeof ratioPadding === 'string'
              ? {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }
              : {},
        }}
      >
        <img src={image?.url} srcSet={image?.srcset || ''} alt={alt || ''} />
      </Box>
      {!!caption && (
        <Typography
          component='figcaption'
          variant='caption'
          className={captionClassName}
          sx={(theme) => ({
            marginTop: theme.spacings.xxxs,
          })}
        >
          {caption}
        </Typography>
      )}
    </Box>
  )
}
