import { Container, Box, Link, Button, Typography } from '@mui/material'
import { CraftImage, CraftImageProps } from '../../../CraftImage'
import { CtaLarge3ImageFragment } from '../Cta.gql'

type CtaLarge3ImageProps = { className: string } & CtaLarge3ImageFragment

export const CtaLarge3Image = ({
  className,
  ctaTitle: title,
  ctaImage: images,
  ctaButtonText: buttonText,
  ctaButtonUrl: buttonUrl,
}: CtaLarge3ImageProps) => (
  <Box
    className={className}
    sx={(theme) => ({
      overflow: 'hidden',
      padding: '40px 0',

      [theme.breakpoints.up('md')]: {
        marginLeft: `calc(${theme.page.horizontal} * -1)`,
        marginRight: `calc(${theme.page.horizontal} * -1)`,
      },
    })}
  >
    <Container
      sx={(theme) => ({
        display: 'grid',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: '1fr',
          gridTemplateRows: `${theme.spacings.sm} ${theme.spacings.sm} repeat(3, auto) ${theme.spacings.sm}`,
          gridTemplateAreas: '"." "image" "image" "title" "button" "."',
        },

        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: '10fr 14fr',
          gridTemplateRows: `${theme.spacings.lg} ${theme.spacings.md} 1fr repeat(2, auto) 1fr ${theme.spacings.md} ${theme.spacings.lg}`,
          gridTemplateAreas: [
            '".      image"',
            '".      image"',
            '".      image"',
            '"title  image"',
            '"button image"',
            '".      image"',
            '".      image"',
            '".      image"',
          ].join(' '),
          columnGap: theme.spacings.xl,
        },

        '&:before': {
          content: '""',
          display: 'block',

          backgroundColor: theme.palette.background.lightContrast,
          alignSelf: 'stretch',
          margin: '0 -100vw',

          [theme.breakpoints.down('md')]: {
            gridColumn: '1 / -1',
            gridRow: '3 / -1',
          },

          [theme.breakpoints.up('md')]: {
            gridColumn: '1 / -1',
            gridRow: '2 / -2',
          },
        },
      })}
    >
      <Box
        sx={(theme) => ({
          gridArea: 'title',
          maxWidth: '500px',

          [theme.breakpoints.up('md')]: {
            maxWidth: '425px',
          },
        })}
      >
        <Typography variant='h1'>{title}</Typography>
      </Box>
      {!!images.length && (
        <Box
          sx={(theme) => ({
            gridArea: 'image',
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gridTemplateRows: 'repeat(12, 1fr)',
            height: '100%',

            [theme.breakpoints.down('md')]: {
              maxWidth: '640px',
            },

            '&:before': {
              content: '""',
              display: 'block',
              paddingTop: '76%',
              gridRow: '1 / -1',
              gridColumn: '1 / -1',
            },
          })}
        >
          {(() => {
            const perImageProps: Array<{
              ratio?: CraftImageProps['ratio']
              sx?: CraftImageProps['sx']
            }> = [
              {
                sx: {
                  gridColumn: '1 / 10',
                  gridRow: '1 / 9',
                },
              },
              {
                sx: {
                  gridColumn: '6 / 12',
                  gridRow: '2 / 12',
                },
              },
              {
                sx: {
                  gridColumn: '2 / 8',
                  gridRow: '1 / -1',
                  borderRadius: '100%',
                  overflow: 'hidden',
                  alignSelf: 'end',
                  paddingTop: '100%',
                },
              },
            ]

            return images.map(
              (image, index) =>
                image !== null && (
                  <CraftImage
                    key={image.url}
                    image={image}
                    ratio={perImageProps[index]?.ratio}
                    sx={{
                      ...perImageProps[index]?.sx,
                      position: 'relative',
                      filter: 'drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.28))',
                      '& img': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      },
                    }}
                  />
                ),
            )
          })()}
        </Box>
      )}
      {!!buttonText && !!buttonUrl && (
        <Link
          href={buttonUrl}
          sx={(theme) => ({
            gridArea: 'button',
            justifySelf: 'start',
            marginTop: theme.spacings.xs,
          })}
        >
          <Button variant='contained'>{buttonText}</Button>
        </Link>
      )}
    </Container>
  </Box>
)
