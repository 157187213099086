import type { CtaSmallRoundImageFragment, CtaLarge3ImageFragment } from './Cta.gql'
import { CtaSmallRoundImage } from './Types/CtaSmallRoundImage'
import { CtaLarge3Image } from './Types/CtaLarge3Image'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'

type CtaState = {
  type: string
}
const name = 'CraftCta' as const
const parts = ['root'] as const

const { withState } = extendableComponent<CtaState, typeof name, typeof parts>(name, parts)

export type CtaProps = CtaSmallRoundImageFragment | CtaLarge3ImageFragment

export const Cta = ({ __typename, ...props }: CtaProps) => {
  const CtaTypeComponents: Record<CtaProps['__typename'], (CtaProps) => JSX.Element> = {
    pageContent_ctaSmallRoundImage_BlockType: CtaSmallRoundImage,
    pageContent_ctaLarge3Image_BlockType: CtaLarge3Image,
  }

  const classes = withState({
    type: __typename.replace('pageContent_', '').replace('_BlockType', ''),
  })

  const CtaTypeComponent = CtaTypeComponents[__typename]

  return <CtaTypeComponent className={classes.root} {...props} />
}
