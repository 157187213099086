import { PageContentComponentProps } from '../../../../types'
import type { ParagraphFragment } from './Paragraph.gql'
import { Container, Typography } from '@mui/material'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'

const name = 'CraftParagraph' as const
const parts = ['root', 'content'] as const
const { classes } = extendableComponent(name, parts)

export type ParagraphProps = PageContentComponentProps & ParagraphFragment

export function Paragraph(props: ParagraphProps) {
  const { mainContent } = props

  if (typeof mainContent !== 'string') {
    return null
  }

  return (
    <Container className={classes.root}>
      <Typography
        className={classes.content}
        component='div'
        variant='bodyLarge'
        dangerouslySetInnerHTML={{ __html: mainContent }}
      />
    </Container>
  )
}
