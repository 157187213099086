import { useMemo, useRef, useState } from 'react'
import { Container, Box, Link, LinkProps, BoxProps } from '@mui/material'
import { FaqListFragment } from './FaqList.gql'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'

const name = 'CraftFaqList' as const
const parts = ['root'] as const
const { classes } = extendableComponent(name, parts)

type FaqListProps = FaqListFragment & {
  allowMultipleOpen?: boolean
}

export function FaqList({ faqContent: content, allowMultipleOpen = true }: FaqListProps) {
  const containerRef = useRef<HTMLDivElement>(null)

  const [activeHeadingIndexes, setActiveHeadingIndexes] = useState<number[]>([])

  const getHeadings = () => {
    if (!containerRef.current) {
      return []
    }

    const siblings: Array<Element> = [...containerRef.current.children]
    return siblings.filter((sibling) => sibling.tagName === 'H1')
  }

  const handleContainerClick = (e) => {
    if (!containerRef.current) {
      return
    }

    const el = e.target as HTMLHeadingElement
    const index = getHeadings().indexOf(el)

    setActiveHeadingIndexes((indexes) => {
      if (!allowMultipleOpen) {
        return [index]
      }

      if (indexes.includes(index)) {
        return indexes.filter((i) => i !== index)
      }

      return [...indexes, index]
    })
  }

  const inactiveHeadingIndexes = useMemo(() => {
    if (!containerRef.current) {
      return []
    }

    const siblingIndexes: Array<number> = Object.keys(getHeadings()).map((i) =>
      typeof i !== 'undefined' ? parseInt(i, 10) : -1,
    )

    return siblingIndexes.filter((i) => i > -1 && !activeHeadingIndexes.includes(i))
  }, [activeHeadingIndexes])

  const hideContentStyles = {}
  for (const index of inactiveHeadingIndexes) {
    hideContentStyles[
      `& h1:nth-of-type(${index + 1}) ~ :not(h1):not(h1:nth-of-type(${index + 2}) ~ *)`
    ] = {
      display: 'none',
    }
  }

  if (Object.keys(hideContentStyles).length === 0) {
    hideContentStyles['& :not(h1)'] = {
      display: 'none',
    }
  }

  return (
    <Container
      className={classes.root}
      ref={containerRef}
      onClick={handleContainerClick}
      dangerouslySetInnerHTML={{ __html: content || '' }}
      sx={(theme) => ({
        '& > h1': {
          ...theme.typography.h3,
          cursor: 'pointer',
          userSelect: 'none',
          display: 'flex',
          margin: `${theme.spacings.sm} 0`,

          '&:not(:first-of-type)': {
            borderTop: `1px solid ${theme.palette.divider}`,
            paddingTop: theme.spacings.sm,
          },

          '&:before': {
            content: '"+"',
            display: 'block',
            marginRight: theme.spacings.xs,
            fontSize: '1.4em',
            lineHeight: 1,
            width: '1em',
          },

          [`&:is(${activeHeadingIndexes.map((i) => `:nth-of-type(${i + 1})`).join(',')})`]: {
            marginBottom: theme.spacings.xxs,

            '&:before': {
              content: '"–"',
            },
          },
        },
        ...hideContentStyles,
      })}
    />
  )
}
