import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { Box, Button, Container, Typography } from '@mui/material'
import { PageContentComponentProps } from '../../../../types'
import type { ButtonGridFragment } from './ButtonGrid.gql'

export type ButtonGridProps = PageContentComponentProps & ButtonGridFragment

type ButtonGridButton = {
  title: string
  url: string
}

const name = 'CraftButtonGrid' as const
const parts = ['root', 'link', 'button'] as const
const { classes } = extendableComponent(name, parts)

export const ButtonGrid = (props: ButtonGridProps) => {
  const {
    buttonGridTitle: gridTitle,
    buttonGridStyle: gridStyle,
    title1,
    url1,
    title2,
    url2,
    title3,
    url3,
    title4,
    url4,
    title5,
    url5,
    title6,
    url6,
    title7,
    url7,
    title8,
    url8,
    title9,
    url9,
  } = props

  const buttons: Array<ButtonGridButton> = []

  const buttonsData = [
    {
      title: title1,
      url: url1,
    },
    {
      title: title2,
      url: url2,
    },
    {
      title: title3,
      url: url3,
    },
    {
      title: title4,
      url: url4,
    },
    {
      title: title5,
      url: url5,
    },
    {
      title: title6,
      url: url6,
    },
    {
      title: title7,
      url: url7,
    },
    {
      title: title8,
      url: url8,
    },
    {
      title: title9,
      url: url9,
    },
  ]

  buttonsData.forEach((buttonData) => {
    if (buttonData) {
      const { title, url } = buttonData

      if (typeof title === 'string' && typeof url === 'string') {
        buttons.push({ title, url })
      }
    }
  })

  const styles: Record<string, (Theme) => object> = {
    default: (theme) => ({}),
    grey: (theme) => ({
      [theme.breakpoints.up('md')]: {
        marginLeft: `calc(${theme.page.horizontal} * -1)`,
        marginRight: `calc(${theme.page.horizontal} * -1)`,
      },

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '100vw',
        top: 0,
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.background.lightContrast,
        alignSelf: 'stretch',
        margin: '0 -100vw',
      },
    }),
  }

  const gridStyleSx = gridStyle ? styles[gridStyle] ?? styles.default : styles.default

  const buttonVariants: Record<string, Parameters<typeof Button>[0]['color']> = {
    default: 'secondary',
    grey: 'primary',
  }

  const gridStyleButtonVariant = gridStyle
    ? buttonVariants[gridStyle] ?? buttonVariants.default
    : buttonVariants.default

  return (
    <Box
      className={classes.root}
      sx={(theme) => ({
        position: 'relative',
        padding: `${theme.spacings.sm} 0`,
        ...(typeof gridStyleSx === 'function' ? gridStyleSx(theme) : gridStyleSx),
      })}
    >
      {!!gridTitle && (
        <Container sx={{ position: 'relative' }}>
          <Box sx={(theme) => ({ textAlign: 'center', marginBottom: theme.spacings.sm })}>
            <Typography variant='h3'>{gridTitle}</Typography>
          </Box>
        </Container>
      )}
      <Container
        sx={(theme) => ({
          [theme.breakpoints.down('xl')]: {
            display: 'grid',

            gap: theme.spacings.xxs,
          },

          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',

            '& > *:nth-child(9)': {
              display: 'none',
            },
          },

          [theme.breakpoints.between('sm', 'lg')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
          },

          [theme.breakpoints.between('lg', 'xl')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
          },

          [theme.breakpoints.up('xl')]: {
            display: 'flex',
            justifyContent: 'space-between',
            gap: theme.spacings.xxs,
            '& > *:nth-child(9)': {
              display: 'none',
            },
          },

          '& a': {
            textDecoration: 'none',
          },

          '& > *': {
            flex: '1 1 0',
          },
        })}
      >
        {buttons.map(({ url, title }) => (
          <Button
            className={classes.link}
            href={url}
            key={`${url}${title}`}
            variant='contained'
            color={gridStyleButtonVariant}
            sx={{ width: '100%', textAlign: 'center' }}
          >
            {title}
          </Button>
        ))}
      </Container>
    </Box>
  )
}
