import { Children } from 'react'
import { Box, Container } from '@mui/material'
import { useRouter } from 'next/router'
import { ContactForm, ContactFormProps, isContactFormType } from '../../../Contact/ContactForm'
import { ProductInterface } from '../../../../.mesh'

type TemplateContactProps = {
  children: React.ReactNode
}

export function TemplateContact(props: TemplateContactProps) {
  const { query } = useRouter()

  const { children } = props
  const childrenArray = Children.toArray(Children.toArray(children))

  let contactFormProps: ContactFormProps = {
    type: 'general',
  }

  if (
    typeof query?.type === 'string' &&
    isContactFormType(query.type) &&
    query.type !== 'general' &&
    typeof query?.name === 'string' &&
    typeof query?.sku === 'string' &&
    typeof query?.artist_option_label === 'string'
  ) {
    contactFormProps = {
      type: query.type,
      product: {
        name: query.name,
        sku: query.sku,
        artist_option_label: query.artist_option_label,
      } as ProductInterface,
    }
  }

  return (
    <Box>
      {!query.type && childrenArray.length > 0 && childrenArray[0]}
      <Container>
        <Box
          sx={(theme) => ({
            display: 'grid',

            [theme.breakpoints.down('lg')]: {
              gridTemplateColumns: '1fr',
              gridTemplateRows: 'repeat(2, auto)',
              gridTemplateAreas: '"contactForm" "content"',
              gridGap: theme.spacings.md,
            },

            [theme.breakpoints.up('lg')]: {
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridTemplateAreas: '"content contactForm"',
              gridGap: theme.spacings.md,
            },
          })}
        >
          <Box sx={{ gridArea: 'content' }}>
            <Box sx={{ '& .MuiContainer-root': { padding: 0 } }}>
              {childrenArray.length > 1 && childrenArray[1]}
            </Box>
            <Box
              sx={{
                paddingTop: '55%',
                position: 'relative',
                '& iframe': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 'none',
                },
              }}
            >
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1228.6986463238327!2d-1.321456!3d51.981412!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3b68434e0b4b2ddf!2sWychwood%20Art!5e0!3m2!1sen!2suk!4v1652390749161!5m2!1sen!2suk'
                loading='lazy'
                title='Wychwood Art'
              />
            </Box>
          </Box>
          <Box sx={{ gridArea: 'contactForm' }}>
            <ContactForm {...contactFormProps} />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
