import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { Container, SxProps, Theme } from '@mui/material'
import { FeefoWidgetFragment } from './FeefoWidget.gql'
import { Widget } from '../../../Feefo/Widget'

type FeefoWidgetProps = FeefoWidgetFragment & {
  sx?: SxProps<Theme>
}

const name = 'CraftFeefoWidget' as const
const parts = ['root'] as const
const { classes } = extendableComponent(name, parts)

export function FeefoWidget(props: FeefoWidgetProps) {
  const { sx } = props

  return (
    <Container className={classes.root} sx={sx}>
      <Widget {...props} />
    </Container>
  )
}
