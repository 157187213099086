import { Theme } from '@mui/material'
import { responsiveVal } from '@graphcommerce/next-ui'

export const sxSmallItem = (theme: Theme) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacings.xs,

  '&.sizeSmall': {
    gridTemplateColumns: `repeat(auto-fill, minmax(${responsiveVal(150, 220)}, 1fr))`,
  },

  '& .ProductListItem-root': {
    '& a': {
      textDecoration: 'none',
    },

    '& .ProductListItem-actions': {
      display: 'none',
    },

    '& .ProductListItem-subtitle': {
      display: 'none',
    },

    '& .ProductListItem-titleContainer': {
      gridTemplateAreas: [
        '"type type type"',
        '"title title title"',
        '"subtitle subtitle subtitle"',
        '"price price price"',
      ].join(' '),
    },

    '& .ProductListItem-title': {
      ...theme.typography.body2,
      lineHeight: 1.6,
    },

    '& .ProductListItem-type': {
      ...theme.typography.body2,
      gridArea: 'type',
    },

    '& .ProductListItem-imageContainer': {
      padding: '100% 0 0 0',
      marginBottom: theme.spacings.xxxs,

      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },

    '& .ProductListPrice-root': {
      ...theme.typography.body2,
      justifySelf: 'stretch',
      textAlign: 'left',
    },
  },
})
