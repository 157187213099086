import { Fragment } from 'react'
import { Box, Container, Link, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { PageContentComponentProps } from '../../../../types'
import type { ImageGridFragment } from './ImageGrid.gql'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CraftImage } from '../../CraftImage'
import { SwiperController } from './SwiperController'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'

const name = 'CraftImageGrid' as const
const parts = [
  'root',
  'title',
  'titleContainer',
  'slider',
  'sliderContainer',
  'gridContainer',
  'slideImage',
  'sliderSlide',
  'sliderLink',
] as const
const { withState } = extendableComponent(name, parts)

export type ImageGridProps = PageContentComponentProps & ImageGridFragment

export function ImageGrid(props: ImageGridProps) {
  const t = useTheme()

  const { cardsTitle, imageContent, images, columns, isSlider } = props

  const useSlider = isSlider === '1'

  let columnCount = 3
  if (typeof columns === 'number') {
    columnCount = Math.min(columns, 4)
  }

  if (!images || images.length === 0) {
    return null
  }

  const classes = withState({
    slider: useSlider,
  })

  const imagesOutput = images.map((image, index) => {
    if (image?.url) {
      let caption = ''
      let url = ''
      let description = ''

      if (imageContent && imageContent[index]) {
        const captionData = imageContent[index]

        if (typeof captionData?.caption === 'string') {
          caption = captionData.caption
        }

        if (typeof captionData?.url === 'string') {
          url = captionData.url
        }

        if (typeof captionData?.description === 'string') {
          description = captionData.description
        }
      }

      const imageOutput = (
        <CraftImage
          imageClassName={classes.slideImage}
          image={image}
          caption={
            <Box>
              <Typography variant='h4'>{caption}</Typography>
              {description && (
                <Typography variant='caption' component='div'>
                  {description}
                </Typography>
              )}
            </Box>
          }
          ratio={{ x: 37, y: 27 }}
        />
      )

      const key = [image.url, caption, url].join('')
      let output = <Fragment key={key}>{imageOutput}</Fragment>

      if (url) {
        output = (
          <Link
            key={key}
            className={classes.sliderLink}
            href={url}
            sx={{ color: 'inherit', textDecoration: 'inherit' }}
          >
            {imageOutput}
          </Link>
        )
      }

      if (useSlider) {
        return (
          <SwiperSlide key={key} className={classes.sliderSlide}>
            {output}
          </SwiperSlide>
        )
      }

      return output
    }

    return null
  })

  let gridSx: SxProps<Theme> = []

  if (columnCount === 1) {
    gridSx = (theme) => ({
      gridTemplateColumns: '1fr',
    })
  } else if (columnCount === 2) {
    gridSx = (theme) => ({
      gridTemplateColumns: '1fr',

      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
      },
    })
  } else if (columnCount === 3 || columnCount === 4) {
    gridSx = (theme) => ({
      gridTemplateColumns: '1fr',

      [theme.breakpoints.between('sm', 'md')]: {
        gridTemplateColumns: `repeat(2, 1fr)`,
      },

      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
      },
    })
  }

  return (
    <Box
      className={classes.root}
      sx={(theme) => ({
        overflow: 'hidden',
        position: 'relative',
        paddingLeft: `${theme.page.horizontal}`,
        paddingRight: `${theme.page.horizontal}`,
        cursor: useSlider ? 'grab' : null,
        marginLeft: `calc(${theme.page.horizontal} * -1)`,

        [theme.breakpoints.up('md')]: {
          marginRight: `calc(${theme.page.horizontal} * -1)`,
        },

        '&:active': {
          cursor: 'grabbing !important',
        },
      })}
    >
      <Container className={classes.titleContainer}>
        <Box
          className={classes.title}
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.spacings.sm,
          })}
        >
          <Box>
            <Typography variant='h3'>
              {cardsTitle}
              {!cardsTitle && useSlider && <>&nbsp;</>}
            </Typography>
          </Box>
          {useSlider && <Box sx={{ width: 50 }} />}
        </Box>
      </Container>
      {useSlider && (
        <Container className={classes.sliderContainer}>
          <Swiper
            className={classes.slider}
            slidesPerView='auto'
            spaceBetween={30}
            style={{ overflow: 'visible' }}
            breakpoints={{
              [t.breakpoints.values.sm]: {
                slidesPerView: Math.ceil(columnCount * 0.3) + 0.6,
              },
              [t.breakpoints.values.md]: {
                slidesPerView: Math.ceil(columnCount * 0.7) + 0.4,
              },
              [t.breakpoints.values.lg]: {
                slidesPerView: columnCount + 0.2,
              },
            }}
          >
            <SwiperController
              sx={(theme) => ({
                position: 'absolute',
                bottom: `calc(100% + (${theme.spacings.sm}))`,
                right: 0,
              })}
            />
            {imagesOutput}
          </Swiper>
        </Container>
      )}
      {!useSlider && (
        <Container
          className={classes.gridContainer}
          sx={[
            (theme) => ({
              display: 'grid',
              gridGap: {
                md: theme.spacings.sm,
                xs: theme.spacings.xs,
              },
            }),
            ...(Array.isArray(gridSx) ? gridSx : [gridSx]),
          ]}
        >
          {imagesOutput}
        </Container>
      )}
    </Box>
  )
}
