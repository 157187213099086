import { Box, Button, Container, Link, Typography } from '@mui/material'
import { CraftImage } from '../../../CraftImage'
import { CtaSmallRoundImageFragment } from '../Cta.gql'

type CtaSmallRoundImageProps = { className: string } & CtaSmallRoundImageFragment

export const CtaSmallRoundImage = ({
  className,
  ctaTitle: title,
  ctaDescription: description,
  ctaButtonText: buttonText,
  ctaButtonUrl: buttonUrl,
  ctaImage: image,
}: CtaSmallRoundImageProps) => {
  let displayTitle = title
  if (typeof displayTitle === 'string') {
    const openBracketMatches = displayTitle.match(/\[/g)
    const closeBracketMatches = displayTitle.match(/]/g)

    if (openBracketMatches?.length !== closeBracketMatches?.length) {
      displayTitle = displayTitle.replace(/[[\]]/g, '')
    }
  }

  return (
    <Container className={className}>
      <Box
        sx={(theme) => ({
          display: 'grid',
          padding: `${theme.spacings.sm} 0`,
          borderTop: `1px solid ${theme.palette.secondary.light}`,
          borderBottom: `1px solid ${theme.palette.secondary.light}`,

          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '120px 1fr',
            gridTemplateRows: 'repeat(4, auto)',
            gridTemplateAreas: '"image ." "title title" "description description" "button button"',
            rowGap: theme.spacings.xxxs,
          },

          // [theme.breakpoints.down('lg')]: {
          //   border: '1px solid red'
          // gridTemplateColumns: '120px 10fr 16fr',
          // gridTemplateRows: 'repeat(2, auto) 1fr auto',
          // gridTemplateAreas:
          //   '"image . description" "title title description" "title title button" ". . button"',
          // rowGap: theme.spacings.xxxs,
          // columnGap: theme.spacings.md,
          // alignItems: 'center',
          // },

          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '160px 10fr 16fr',
            gridTemplateRows: 'repeat(2, auto)',
            gridTemplateAreas: '"image title description" "image title button"',
            columnGap: theme.spacings.md,
            alignItems: 'center',
          },
        })}
      >
        {!!image[0] && (
          <CraftImage
            image={image[0]}
            ratio={{ x: 1, y: 1 }}
            sx={{ gridArea: 'image', borderRadius: '100%', overflow: 'hidden' }}
          />
        )}
        {displayTitle && (
          <Box sx={{ gridArea: 'title' }}>
            <Typography
              variant='h2'
              dangerouslySetInnerHTML={{
                __html: displayTitle?.replace(/\[/g, '<span>').replace(/\]/g, '</span>'),
              }}
              sx={(theme) => ({
                '& span': {
                  color: theme.palette.primary.main,
                },
              })}
            />
          </Box>
        )}
        {description && <Box sx={{ gridArea: 'description' }}>{description}</Box>}
        {!!buttonText && !!buttonUrl && (
          <Button href={buttonUrl} variant='contained' sx={{ gridArea: 'button', width: '100%' }}>
            {buttonText}
          </Button>
        )}
      </Box>
    </Container>
  )
}
