import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { Box, Theme } from '@mui/material'
import { SpacerFragment } from './Spacer.gql'

type SpacerProps = SpacerFragment

const name = 'CraftSpacer' as const
const parts = ['root'] as const
const { classes } = extendableComponent(name, parts)

export function Spacer({ size }: SpacerProps) {
  const sizeMapping: Record<string, keyof Theme['spacings']> = {
    m: 'md',
    s: 'sm',
    l: 'lg',
  }

  return (
    <Box
      className={classes.root}
      sx={(theme) => {
        if (size && size in theme.spacings) {
          return { height: theme.spacings[size] }
        }

        if (size && sizeMapping[size] in theme.spacings) {
          return { height: theme.spacings[sizeMapping[size]] }
        }

        return {
          display: 'none',
          clear: 'both',
        }
      }}
    />
  )
}
