import { Box, Container, Typography } from '@mui/material'
import type { TextWithImageFragment } from './TextWithImage.gql'
import { CraftImage } from '../../CraftImage'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'

const name = 'CraftTextWithImage' as const
const parts = ['root', 'image'] as const
const { classes } = extendableComponent(name, parts)

export type TextWithImageProps = TextWithImageFragment

export function TextWithImage(props: TextWithImageProps) {
  const { text, image } = props

  if (!image || image.length === 0) {
    return null
  }

  const [singleImage] = image

  if (!singleImage) {
    return null
  }

  return (
    <Container className={classes.root}>
      <Box
        sx={(theme) => ({
          display: 'grid',

          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'repeat(2, auto)',
            gridGap: theme.spacings.sm,
          },

          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: theme.spacings.md,
          },
        })}
      >
        <CraftImage
          sx={(theme) => ({
            margin: 0,

            '& img': {
              display: 'block',
              width: '100%',
              height: 'auto',

              [theme.breakpoints.down('md')]: {
                maxHeight: '70vh',
                objectFit: 'contain',
              },
            },
          })}
          image={singleImage}
          imageClassName={classes.image}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            component='div'
            variant='bodyLarge'
            dangerouslySetInnerHTML={{ __html: text || '' }}
          />
        </Box>
      </Box>
    </Container>
  )
}
