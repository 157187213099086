import { TemplateDefault, TemplateContact } from './Template'

const templateMap = {
  default: TemplateDefault,
  contact: TemplateContact,
}

export type PageTemplateNames = keyof typeof templateMap

type PageTemplateRendererProps = {
  template: PageTemplateNames | null | undefined
  children: React.ReactNode
}

export function PageTemplateRenderer(props: PageTemplateRendererProps) {
  const { template, children } = props

  let Template

  if (template) {
    Template = templateMap[template] ?? templateMap.default
  } else {
    Template = templateMap.default
  }

  return <Template>{children}</Template>
}
