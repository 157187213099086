import { createElement, Fragment } from 'react'

type BrandHighlightProps = React.PropsWithChildren<{
  tag?: string
}>

export function BrandHighlight({ children, tag = 'span' }: BrandHighlightProps) {
  if (typeof children === 'string') {
    const parts = children.split(/(\[|\])/).filter(Boolean)
    const output: React.ReactNode[] = []

    let skipNext = false
    parts.forEach((part, index) => {
      if (skipNext) {
        skipNext = false
        return
      }

      if (part === '[') {
        output.push(
          createElement(
            tag,
            {
              key: `${part}${index.toString()}`,
              className: 'brand-highlight',
            },
            parts[Number(index) + 1] ?? null,
          ),
        )

        skipNext = true
      } else if (part !== ']') {
        output.push(
          createElement(
            Fragment,
            {
              key: `${part}${index.toString()}`,
            },
            part,
          ),
        )
      }
    })

    return <>{output}</>
  }

  return <>{children}</>
}
