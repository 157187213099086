import { RenderType, TypeRenderer } from '@graphcommerce/next-ui'
import { PageTemplateNames, PageTemplateRenderer } from './PageTemplate'
import { PageContentRendererFragment } from './PageContentRenderer.gql'
import { PageContentComponentProps } from '../../types'
import { ButtonGrid } from './PageContent/ButtonGrid/ButtonGrid'
import { Paragraph } from './PageContent/Paragraph/Paragraph'
import { Heading } from './PageContent/Heading/Heading'
import { SingleImage } from './PageContent/SingleImage/SingleImage'
import { ImageGrid } from './PageContent/ImageGrid/ImageGrid'
import { TextWithImage } from './PageContent/TextWithImage/TextWithImage'
import { Cta } from './PageContent/Cta/Cta'
import { Usp } from './PageContent/Usp/Usp'
import { HeroGallery } from './PageContent/HeroGallery/HeroGallery'
import { ProductGrid } from './PageContent/ProductGrid/ProductGrid'
import { Spacer } from './PageContent/Spacer/Spacer'
import { FaqList } from './PageContent/FaqList/FaqList'
import { FeefoWidget } from './PageContent/FeefoWidget/FeefoWidget'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { Box } from '@mui/material'

type ContentTypeRenderer = TypeRenderer<
  NonNullable<PageContentRendererFragment['pageContent'][0]> & PageContentComponentProps
>

const defaultRenderer: Partial<ContentTypeRenderer> = {
  pageContent_paragraph_BlockType: Paragraph,
  pageContent_heading_BlockType: Heading,
  pageContent_singleImage_BlockType: SingleImage,
  pageContent_imageGrid_BlockType: ImageGrid,
  pageContent_ctaSmallRoundImage_BlockType: Cta,
  pageContent_ctaLarge3Image_BlockType: Cta,
  pageContent_usp_BlockType: Usp,
  pageContent_heroGallery_BlockType: HeroGallery,
  pageContent_productGrid_BlockType: ProductGrid,
  pageContent_buttonGrid_BlockType: ButtonGrid,
  pageContent_spacer_BlockType: Spacer,
  pageContent_faqList_BlockType: FaqList,
  pageContent_feefoWidget_BlockType: FeefoWidget,
  pageContent_textWithImage_BlockType: TextWithImage,
}

const { classes } = extendableComponent('PageContentRenderer', ['root'] as const)

export type PageProps = PageContentRendererFragment & {
  locale: string
  template?: PageTemplateNames
  renderer?: Partial<ContentTypeRenderer>
}

export function PageContentRenderer(props: PageProps) {
  const { pageContent, template, renderer, locale } = props

  const mergedRenderer = { ...defaultRenderer, ...renderer } as ContentTypeRenderer

  return (
    <Box className={classes.root}>
      <PageTemplateRenderer template={template}>
        {pageContent?.map((item, i) => {
          if (item) {
            return (
              <RenderType
                renderer={mergedRenderer}
                key={item?.id || i}
                locale={locale}
                index={i}
                {...item}
              />
            )
          }

          return null
        })}
      </PageTemplateRenderer>
    </Box>
  )
}
