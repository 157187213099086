import { useSwiper } from 'swiper/react'
import { Box, Button, IconButton, SxProps, Theme } from '@mui/material'
import { iconChevronLeft, iconChevronRight, IconSvg } from '@graphcommerce/next-ui'

type SwiperControllerProps = {
  sx: SxProps<Theme>
}

export function SwiperController(props: SwiperControllerProps) {
  const { sx } = props

  const swiper = useSwiper()

  return (
    <Box
      sx={[
        (theme) => ({
          display: 'flex',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <IconButton size='small' onClick={() => swiper.slidePrev()}>
        <IconSvg src={iconChevronLeft} />
      </IconButton>
      <IconButton size='small' onClick={() => swiper.slideNext()}>
        <IconSvg src={iconChevronRight} />
      </IconButton>
    </Box>
  )
}
