type TemplateDefaultProps = {
    children: React.ReactNode;
}

export function TemplateDefault(props: TemplateDefaultProps) {
    const {children} = props;

    return (
        <>
            {children}
        </>
    );
}